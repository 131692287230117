import React from 'react';
import { NeonIllustration } from '@ps-refarch-ux/neon';
import './empty-state.scss';
export default function EmptyState(_a) {
    var type = _a.type;
    var heading = type === 'smart-groups' ? 'No smart groups found' : 'No students found';
    var subheading = type === 'smart-groups' ? React.createElement(React.Fragment, null,
        React.createElement("div", null, "When smart groups are active on your site,"),
        React.createElement("div", null, "they will display here.")) : React.createElement(React.Fragment, null,
        React.createElement("div", null, "Students are automatically added to the"),
        React.createElement("div", null, "group when identified criteria are met or they"),
        React.createElement("div", null, "can be added manually."));
    return (React.createElement("div", { className: 'mfe-beh-smart-groups-empty-state' },
        React.createElement("div", { className: 'mfe-beh-smart-groups-empty-state-illustration' },
            React.createElement(NeonIllustration, { dataIllustration: 'folder' })),
        React.createElement("div", { className: 'mfe-beh-smart-groups-empty-state-heading' }, heading),
        React.createElement("br", null),
        React.createElement("div", { className: 'mfe-beh-smart-groups-empty-state-subheading' }, subheading)));
}
