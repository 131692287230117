import React, { useContext } from 'react';
import { NeonModalDrawer } from '@ps-refarch-ux/neon';
import AddStudent from '../../../components/smart-groups/smart-group-table/add-student';
import SmartGroupTableView from '../../../components/smart-groups/smart-group-table/smart-group-view-student-behavior';
import { SidePanelViewContext } from '../../context/smart-group-context';
import Settings from '../../../components/smart-groups/smart-group-table/settings';
import { SidePanelView } from '../../constants';
var ConsequenceRosterSidePanel = function (_a) {
    var viewBehaviorData = _a.viewBehaviorData, selectedStudentInfo = _a.selectedStudentInfo, addStudentConfirmResponseHandler = _a.addStudentConfirmResponseHandler, settingsApplyHandler = _a.settingsApplyHandler, consequenceId = _a.consequenceId, consequenceType = _a.consequenceType;
    var sidePanelViewContext = useContext(SidePanelViewContext);
    return (React.createElement(NeonModalDrawer, { id: "mfe-beh-smart-groups-side-panel", neonDrawerHasClosed: function () {
            sidePanelViewContext.setSidePanelView('');
        }, "data-testid": "consequence-roster-side-panel" },
        sidePanelViewContext.sidePanelView === SidePanelView.AddStudent &&
            React.createElement(AddStudent, { addStudentConfirmResponseHandler: addStudentConfirmResponseHandler, consequenceId: consequenceId }),
        sidePanelViewContext.sidePanelView === SidePanelView.ViewStudent &&
            React.createElement(SmartGroupTableView, { viewBehaviorData: viewBehaviorData, selectedStudentInfo: selectedStudentInfo }),
        sidePanelViewContext.sidePanelView === SidePanelView.Settings &&
            React.createElement(Settings, { consequenceType: consequenceType, settingsApplyHandler: settingsApplyHandler })));
};
export default ConsequenceRosterSidePanel;
