var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../../../../common/context/auth-context';
import { SmartGroupApi } from '../../../../services/api/smart-groups-mfe-api';
import { NeonButton } from '@ps-refarch-ux/neon';
import './smart-group-view-student-behavior.scss';
import { SidePanelViewContext } from '../../../../common/context/smart-group-context';
import { NeonLoadingIndicator } from '@ps-refarch-ux/neon';
var initialState = {
    error: false,
    data: [],
    loading: false,
};
var renderConsequenceViewBody = function (item, index) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "mfe-beh-smart-groups-consequence_view_body", id: "".concat(index, "-mfe-beh-smart-groups-consequence_view_body") },
            React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body_parent' },
                React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body__left_section' },
                    React.createElement("p", null, item.formatted_assigned_date),
                    React.createElement("p", null, item.formatted_assigned_time)),
                React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body__right_section' },
                    React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body__right_section_notes_header' },
                        React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body__right_section_notes_header_child' },
                            React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body__right_section_notes_header__text_p' },
                                React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body__right_section_notes_header__text_p_left' },
                                    React.createElement("span", null, item.behavior_name)),
                                React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body__right_section_notes_header__text_c' },
                                    React.createElement("span", null, item.staff_name))),
                            item.formatted_dollar_merit_value && React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body__right_section_notes_header__text_p_right' },
                                React.createElement("span", null, item.formatted_dollar_merit_value)))),
                    React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_view_body__right_section_notes_body' },
                        React.createElement("span", null, item.comments)))),
            React.createElement("div", { className: "mfe-beh-smart-groups-line-after-iteration" }))));
};
var SmartGroupTableView = function (_a) {
    var viewBehaviorData = _a.viewBehaviorData, selectedStudentInfo = _a.selectedStudentInfo;
    var _b = useState(initialState), viewState = _b[0], setViewState = _b[1];
    var sidePanelViewContext = useContext(SidePanelViewContext);
    var _c = useContext(AuthContext), authToken = _c.authToken, analysisTimePeriod = _c.analysisTimePeriod;
    // create a useeffect which is called when view button is clicked. when closing the side panel we need to reset the view behavior data.
    var getViewDataFromBehaviorApi = useCallback(function (currentStudentId) {
        setViewState(function (prevState) { return __assign(__assign({}, prevState), { loading: true }); });
        SmartGroupApi.getStudentsNotesAndBehaviors(authToken, currentStudentId, selectedStudentInfo.consequenceId, analysisTimePeriod, function (studentBehaviorData) {
            setViewState({ error: false, data: studentBehaviorData[0].behaviors, loading: false });
        }, function (error) {
            setViewState({ error: true, data: [], loading: false });
        });
    }, [selectedStudentInfo.consequenceId, authToken]);
    //convert above function to useeffect.
    useEffect(function () {
        getViewDataFromBehaviorApi(selectedStudentInfo.studentId);
    }, [selectedStudentInfo.studentId, getViewDataFromBehaviorApi]);
    var handleRefreshClick = function () {
        getViewDataFromBehaviorApi(selectedStudentInfo.studentId);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-slot": "drawer-header-title" }, "Details"),
        React.createElement("div", { "data-slot": "drawer-body" },
            React.createElement("div", { className: 'mfe-beh-smart-groups-crstr_student_name' },
                React.createElement("span", null, selectedStudentInfo.studentName)),
            viewState.loading && !viewState.error && viewBehaviorData.map(function (item, index) {
                return renderConsequenceViewBody(item, index);
            }),
            viewState.error && viewBehaviorData.map(function (item, index) {
                return (React.createElement(React.Fragment, null, renderConsequenceViewBody(item, index)));
            }),
            viewState.loading && (React.createElement("div", { className: "mfe-beh-smart-groups-view_behavior_loader" },
                React.createElement(NeonLoadingIndicator, { dataSize: "medium" }))),
            viewState.error && !viewState.loading && (React.createElement("div", { className: "mfe-beh-smart-groups-view_behavior_error" },
                React.createElement("div", { className: 'mfe-beh-smart-groups-view_behavior_error_heading' }, "There was an error loading older items"),
                React.createElement("div", { className: 'mfe-beh-smart-groups-view_behavior_error_content' },
                    React.createElement("span", { onClick: handleRefreshClick }, "Refresh"),
                    " the panel"))),
            viewState.data && viewState.data.length > 0 && viewState.data.map(function (item, index) {
                return renderConsequenceViewBody(item, index);
            })),
        React.createElement("div", { "data-slot": "drawer-footer-content" },
            React.createElement("div", { className: "neon-2_15_0-button-layout" },
                React.createElement(NeonButton, { id: "mfe-beh-smart-groups-model_drawer_close_btn", dataTooltipText: "Close", dataText: "Close", dataType: "secondary", onClick: function () {
                        sidePanelViewContext.setSidePanelView('');
                    } })))));
};
export default SmartGroupTableView;
