var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NeonButton, NeonDateField, NeonSelectField, NeonTextareaField } from '@ps-refarch-ux/neon';
import React, { useContext, useEffect, useState } from 'react';
import './add-student.scss';
import DOMPurify from 'dompurify';
import { ModalLoaderContext, GroupsAndstudentsContext, SidePanelViewContext, SchoolYearContext } from '../../../../common/context/smart-group-context';
import { SmartGroupApi } from '../../../../services/api/smart-groups-mfe-api';
import { AuthContext } from '../../../../common/context/auth-context';
import { validateInput, validateInputForButtonDisable } from '../../../../common/components/helper/html-tag-validator';
import { SidePanelView } from '../../../../common/constants';
import { DateHelper } from '../../../../common/helper/date-helper';
export default function AddStudent(_a) {
    var addStudentConfirmResponseHandler = _a.addStudentConfirmResponseHandler, consequenceId = _a.consequenceId;
    var _b = useContext(AuthContext), authToken = _b.authToken, analysisTimePeriod = _b.analysisTimePeriod;
    var modalLoaderContext = useContext(ModalLoaderContext);
    var groupsAndStudentsContext = useContext(GroupsAndstudentsContext);
    var sidePanelViewContext = useContext(SidePanelViewContext);
    var schoolYearContext = useContext(SchoolYearContext);
    var _c = useState(), studentGroup = _c[0], setStudentGroup = _c[1];
    var _d = useState(), studentEnrollment = _d[0], setStudentEnrollment = _d[1];
    var _e = useState([]), studentsOptions = _e[0], setStudentsOptions = _e[1];
    var _f = useState(), reason = _f[0], setReason = _f[1];
    var _g = useState(), date = _g[0], setDate = _g[1];
    var _h = useState(''), dateError = _h[0], setDateError = _h[1];
    var _j = useState(false), isFormValid = _j[0], setIsFormValid = _j[1];
    // validating form fields whenever form fields are updated
    useEffect(function () {
        setIsFormValid(studentGroup && studentEnrollment && ((reason && (reason === null || reason === void 0 ? void 0 : reason.length) > 0 && (reason === null || reason === void 0 ? void 0 : reason.length) <= 64) && !validateInputForButtonDisable(reason)) && (date && !dateError) ? true : false);
    }, [studentGroup, studentEnrollment, reason, date]);
    // showing modal loader if groups and students are not fetched when Add Student drawer is opened
    useEffect(function () {
        modalLoaderContext.setModalLoaderView(groupsAndStudentsContext.loading ? 'before-add-student' : '');
    }, [groupsAndStudentsContext.loading]);
    // Checking if date is in school year range (if we have the school year data) and setting error accordingly
    var checkDate = function (date) {
        if (date === undefined) {
            setDateError('');
            return;
        }
        if (schoolYearContext.start_date && schoolYearContext.end_date) {
            if (date < DateHelper.getDate(schoolYearContext.start_date)) {
                setDateError("The date must be on or after ".concat(schoolYearContext.start_date && DateHelper.getDate(schoolYearContext.start_date).toLocaleDateString()));
            }
            else if (date > DateHelper.getDate(schoolYearContext.end_date)) {
                setDateError("The date must be on or before ".concat(schoolYearContext.end_date && DateHelper.getDate(schoolYearContext.end_date).toLocaleDateString()));
            }
            else {
                setDateError('');
            }
        }
        else {
            setDateError('');
        }
    };
    // Closing side panel, showing modal loader and calling add student api after Confirm button is clicked
    var onConfirmHandler = function (event) {
        var _a, _b;
        event.preventDefault();
        sidePanelViewContext.setSidePanelView('');
        modalLoaderContext.setModalLoaderView(SidePanelView.AddStudent);
        var assigned_date = DateHelper.getDate(date);
        var studentId = (_a = studentsOptions === null || studentsOptions === void 0 ? void 0 : studentsOptions.find(function (student) { return student.value === studentEnrollment; })) === null || _a === void 0 ? void 0 : _a.id;
        var studentName = (_b = studentsOptions === null || studentsOptions === void 0 ? void 0 : studentsOptions.find(function (student) { return student.value === studentEnrollment; })) === null || _b === void 0 ? void 0 : _b.name;
        var requestBody = {
            'consequence_id': consequenceId,
            'group_id': studentGroup,
            'enrollment_id': studentEnrollment,
            'reason': DOMPurify.sanitize(reason || ''),
            'assigned_date': assigned_date.toDateString(),
            'student_id': String(studentId),
            'startDate': analysisTimePeriod.startDate,
            'endDate': analysisTimePeriod.endDate
        };
        SmartGroupApi.addStudent(authToken, requestBody, function (message, studentListItem) {
            addStudentConfirmResponseHandler(true, studentName, message || 'success', studentListItem);
        }, function (errorMessage) {
            addStudentConfirmResponseHandler(false, studentName, errorMessage || 'error');
        });
    };
    // updating button props (disabled and dataTooltipText) based on form validation
    var buttonProps = isFormValid ? {
        disabled: false,
    } : {
        disabled: true,
        dataTooltipText: 'Enter all the data before confirming'
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-slot": 'drawer-header-title' }, "Add student"),
        React.createElement("div", { "data-slot": 'drawer-body' },
            React.createElement("div", null,
                React.createElement(NeonSelectField, { id: 'mfe-beh-smart-groups-add-group', dataLabelText: 'Student group', dataPlaceholderText: 'Select a Group', dataShowLabel: true, 
                    // dataIsRequired={true}
                    options: groupsAndStudentsContext.groupsAndStudents, modelValue: studentGroup, modelValueChange: function (selectedStudentGroupId) {
                        var _a;
                        setStudentGroup(selectedStudentGroupId);
                        setStudentsOptions((_a = groupsAndStudentsContext.groupsAndStudents.find(function (groupAndStudents) { return groupAndStudents.value === selectedStudentGroupId; })) === null || _a === void 0 ? void 0 : _a.students);
                    }, className: 'mfe-beh-smart-groups-add-group-student' })),
            React.createElement("div", null,
                React.createElement(NeonSelectField, { id: 'mfe-beh-smart-groups-add-student', dataLabelText: 'Student', dataShowLabel: true, 
                    // dataIsRequired={true}
                    dataHideRequiredBadge: false, dataPlaceholderText: 'Select a Student', options: studentsOptions ? studentsOptions.sort(function (a, b) { return a.name.localeCompare(b.name); }) : [], modelValue: studentEnrollment, modelValueChange: function (selectedStudent) { setStudentEnrollment(selectedStudent); }, className: 'mfe-beh-smart-groups-add-group-student' })),
            React.createElement("div", null,
                React.createElement(NeonTextareaField, { id: 'mfe-beh-smart-groups-add-reason', dataLabelText: 'Reason', dataShowLabel: true, 
                    // dataIsRequired={true}
                    dataHideRequiredBadge: false, dataPlaceholderText: 'Enter Reason', customValidationFunction: validateInput, dataMaxLength: 64, modelValue: reason, modelValueChange: function (reason) { setReason(reason); }, className: 'mfe-beh-smart-groups-add-reason' })),
            React.createElement("div", null,
                React.createElement(NeonDateField, { id: 'mfe-beh-smart-groups-add-date', dataLabelText: 'Date', dataShowLabel: true, 
                    // dataIsRequired={true}
                    dataPlaceholderText: 'Select Date', modelValue: date, modelValueChange: function (date) {
                        checkDate(date);
                        setDate(date);
                    } }),
                dateError && React.createElement("p", { className: 'mfe-beh-smart-groups-add-date-error', "aria-live": 'assertive', "aria-relevant": 'additions removals' }, dateError))),
        React.createElement("div", { "data-slot": 'drawer-footer-content' },
            React.createElement("div", { className: 'neon-2_15_0-button-layout', "data-testid": 'focusable-element' },
                React.createElement(NeonButton, { id: 'mfe-beh-smart-groups-add-cancel', dataText: 'Cancel', dataType: 'Default', onClick: function (event) {
                        event.preventDefault();
                        sidePanelViewContext.setSidePanelView('');
                    } }),
                React.createElement("div", null,
                    React.createElement(NeonButton, __assign({ id: 'mfe-beh-smart-groups-add-confirm', dataText: 'Confirm', dataType: 'primary', onClick: onConfirmHandler }, buttonProps)))))));
}
