var SmartGroupsConfig = /** @class */ (function () {
    function SmartGroupsConfig() {
    }
    var _a;
    _a = SmartGroupsConfig;
    SmartGroupsConfig.hexsToNeons = [
        { '#993300': 'red' },
        { '#333300': 'gray' },
        { '#333399': 'purple' },
        { '#800000': 'red' },
        { '#ff6600': 'orange' },
        { '#808000': 'gray' },
        { '#008000': 'green' },
        { '#008080': 'teal' },
        { '#666699': 'purple' },
        { '#808080': 'gray' },
        { '#ff0000': 'red' },
        { '#ff9900': 'orange' },
        { '#99cc00': 'green' },
        { '#339966': 'green' },
        { '#33cccc': 'teal' },
        { '#3366ff': 'navy' },
        { '#800080': 'red' },
        { '#999999': 'gray' },
        { '#ff00ff': 'magenta' },
        { '#ffcc00': 'yellow' },
        { '#03c903': 'green' },
        { '#00ccff': 'cyan' },
        { '#993366': 'red' },
        { '#c0c0c0': 'gray' }
    ];
    SmartGroupsConfig.NeonColorWeight = '400';
    SmartGroupsConfig.getIconNeonColor = function (hexCode) {
        var hexToNeon = _a.hexsToNeons.find(function (hexToNeon) { return Object.keys(hexToNeon)[0] === hexCode; });
        return hexToNeon ? hexToNeon[hexCode] : 'gray';
    };
    return SmartGroupsConfig;
}());
export { SmartGroupsConfig };
export var formatSmartGroups = function (smartGroups) {
    return smartGroups.map(function (smartGroup) {
        smartGroup.text = smartGroup.description;
        smartGroup.value = String(smartGroup.consequence_id);
        smartGroup.icon = 'bookmark-flag';
        smartGroup.iconColor = SmartGroupsConfig.getIconNeonColor(smartGroup.flag_color);
        smartGroup.iconColorWeight = SmartGroupsConfig.NeonColorWeight;
        return smartGroup;
    });
};
export var formatGroupsAndStudents = function (groupsAndStudents) {
    return groupsAndStudents.map(function (groupAndStudents) {
        groupAndStudents.text = String(groupAndStudents.group_name);
        groupAndStudents.value = String(groupAndStudents.group_id);
        groupAndStudents.students.map(function (student) {
            student.text = String(student.name);
            student.value = String(student.enrollment_id);
            return student;
        });
        return groupAndStudents;
    });
};
export var formatGrades = function (grades) {
    return grades.map(function (grade) {
        return {
            id: String(grade.value),
            name: String(grade.value),
            labelText: String(grade.label)
        };
    });
};
