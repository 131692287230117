/*
    comments for addStudent
    @param StudentName - Student Name.
    @param ConsequenceName - Consequence Name.
    @returns string - formatted message.
*/
export var addStudent = {
    successBody: function (StudentName, ConsequenceName) { return "".concat(StudentName, " was successfully added to smart group ").concat(ConsequenceName, "."); },
    NotificationHeader: 'Add student',
    errorBody: function (StudentName, ConsequenceName) { return "".concat(StudentName, " was not added to smart group ").concat(ConsequenceName, ". Please try again."); },
    alreadyAddedBody: function (StudentName, ConsequenceName) { return "".concat(StudentName, " is already in smart group ").concat(ConsequenceName, "."); },
};
/*
    comments for deleteStudent
    @param StudentName - Student Name.
    @param ConsequenceName - Consequence Name.
    @returns string - formatted message.
*/
export var deleteStudent = {
    body: function (StudentName, ConsequenceName) { return "Are you sure you want to delete ".concat(StudentName, " from smart group ").concat(ConsequenceName, "?"); },
    NotificationHeader: 'Delete',
    successBody: function (StudentName, ConsequenceName) { return "".concat(StudentName, " successfully deleted from smart group ").concat(ConsequenceName, "."); },
    errorBody: function (StudentName, ConsequenceName) { return "".concat(StudentName, " not deleted from smart group ").concat(ConsequenceName, ". Please try again."); },
};
/*
    comments for markCompleted
    @param StudentName - Student Name.
    @param ConsequenceName - Consequence Name.
    @returns string - formatted message.
*/
export var markCompleted = {
    body: function (StudentName, ConsequenceName) { return "Are you sure you want to mark ".concat(StudentName, " complete for smart group ").concat(ConsequenceName, "?"); },
    NotificationHeader: 'Mark complete',
    successBody: function (StudentName, ConsequenceName) {
        return "".concat(StudentName, " successfully marked complete for smart group ").concat(ConsequenceName, ".");
    },
    errorBody: function (StudentName, ConsequenceName) { return "".concat(StudentName, " not marked complete for smart group ").concat(ConsequenceName, ". Please try again."); },
};
/*
    comments for processStudentNames
    @param map - Map of student names.
    @returns string - formatted student names.
*/
export var processStudentNames = function (map, notification) {
    var mapSize = map.size;
    if (mapSize === 0) {
        return '';
    }
    var result = Array.from(map.entries())
        .slice(0, Math.min(mapSize, 2))
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        var names = value.studentName.split(' ');
        var formattedName = names.slice(0, names.length).join(' ');
        return formattedName;
    });
    var verb = notification ? mapSize === 1 ? 'was' : (mapSize === 2 ? 'were' : 'students were') : (mapSize > 2 ? 'students' : '');
    return mapSize > 2 ? "".concat(mapSize, " ").concat(verb) : "".concat(result.join(' and '), " ").concat(verb);
};
/*
    comments for AddStudentNotesSmartGroups
    @param map - Map of student names.
    @returns string - formatted student names.
*/
export var AddStudentNotesSmartGroups = {
    success: function (studentName, selectedStudentSize) {
        return "Your note was successfully added to ".concat(studentName, ".");
    },
    error: function (studentName, selectedStudentSize) {
        return "Your note was not added to ".concat(studentName, ". Please try again.");
    },
    systemMessageHeader: 'Add note',
};
