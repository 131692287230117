var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import './smart-group-add-notes.scss';
import DOMPurify from 'dompurify';
import { DEFAULT_SYSTEM_MESSAGES_FLOATING_ID } from '@ps-refarch-ux/neon';
import { NeonModalDialog, NeonButton, NeonTextareaField } from '@ps-refarch-ux/neon';
import { SmartGroupApi } from '../../../../services/api/smart-groups-mfe-api';
import { showSystemMessage } from '@ps-refarch-ux/neon';
import { ADD_STUDENT_NOTES_SAVE_TOOLTIP_DISABLE, MAX_NOTES_VALUE } from '../smart-group-table-config';
import { AddStudentNotesSmartGroups, processStudentNames } from '../../../../common/components/helper/common-message-helper';
import { validateInput, validateInputForButtonDisable } from '../../../../common/components/helper/html-tag-validator';
export var smartGroupAddNotesOnSave = function (authToken, smartGroupId, selectedStudents, description, addStudentNotesConfirmResponseHandler) {
    // use validate input function.
    var selectedStudentsArray = Array.from(selectedStudents.values());
    var requestBody = {
        consequence_id: Number(smartGroupId),
        consequence_roster_note: DOMPurify.sanitize(description),
        consequence_roster_details: selectedStudentsArray.map(function (value) {
            return {
                consequence_roster_ids: value.consequenceRosterIds,
                student_id: Number(value.studentId),
                student_enrollment_id: Number(value.studentEnrollmentId)
            };
        })
    };
    SmartGroupApi.addStudentNotes(authToken, requestBody, function (message) {
        addStudentNotesConfirmResponseHandler(true, message);
    }, function (errorMessage) {
        addStudentNotesConfirmResponseHandler(false, errorMessage);
    });
};
var SmartGroupAddNotes = function (_a) {
    var setAddNotesModalView = _a.setAddNotesModalView, selectedStudents = _a.selectedStudents, smartGroupId = _a.smartGroupId, authToken = _a.authToken, updateLastUpdated = _a.updateLastUpdated;
    var _b = useState(''), description = _b[0], setDescription = _b[1];
    var _c = useState(false), showTooltip = _c[0], setShowTooltip = _c[1];
    // useEffect for updating button props (disabled and dataTooltipText) based on form validation
    useEffect(function () {
        setShowTooltip(description === undefined || description === '');
    }, [description]);
    var addStudentNotesConfirmResponseHandler = function (success, message) {
        var extractedNames = processStudentNames(selectedStudents, false);
        var selectedStudentSize = selectedStudents.size;
        if (success) {
            // updating last updated time
            updateLastUpdated();
            showSystemMessage(AddStudentNotesSmartGroups.success(extractedNames, selectedStudentSize), {
                sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                messageType: 'success',
                title: AddStudentNotesSmartGroups.systemMessageHeader,
            });
        }
        else {
            showSystemMessage(AddStudentNotesSmartGroups.error(extractedNames, selectedStudentSize), {
                sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                messageType: 'error',
                title: AddStudentNotesSmartGroups.systemMessageHeader,
            });
        }
        setAddNotesModalView(false);
    };
    var addNotesModalCloseHandler = function () {
        setAddNotesModalView(false);
    };
    // updating button props (disabled and dataTooltipText) based on form validation
    var isDisabled = showTooltip || validateInputForButtonDisable(description) || (description && description.length >= MAX_NOTES_VALUE);
    var buttonProps = isDisabled
        ? { disabled: true,
            dataTooltipText: showTooltip ? ADD_STUDENT_NOTES_SAVE_TOOLTIP_DISABLE : '' }
        : { disabled: false };
    return (React.createElement(NeonModalDialog, { id: "mfe-beh-smart-groups-modal-dialog-header", neonDialogHasClosed: addNotesModalCloseHandler, "data-testid": "mfe-beh-smart-groups-modal-dialog-header-add-notes" },
        React.createElement("div", { "data-slot": "dialog-header-title" }, "Add note"),
        React.createElement("div", { "data-slot": "dialog-body" },
            React.createElement("div", { className: 'mfe-beh-smart-groups-smgan__dialog_body_wrapper' },
                React.createElement("div", { className: 'mfe-beh-smart-groups-smgan__student_length' },
                    React.createElement("span", null, selectedStudents.size > 1 ? "".concat(selectedStudents.size, " students selected") : '1 student selected')),
                React.createElement("div", { className: "mfe-beh-smart-groups-smgan__dialog_body" },
                    React.createElement(NeonTextareaField, { id: "mfe-beh-smart-groups-description", dataLabelText: "Description", dataShowLabel: "false", modelValue: description, dataMaxLength: MAX_NOTES_VALUE, customValidationFunction: validateInput, dataPlaceholderText: 'Type your note here', dataShowCount: 'false', modelValueChange: function (desc) {
                            setDescription(desc && desc.length <= MAX_NOTES_VALUE ? desc : (desc === null || desc === void 0 ? void 0 : desc.slice(0, MAX_NOTES_VALUE)) || '');
                        } })),
                React.createElement("div", { className: 'mfe-beh-smart-groups-smgan__notes_length' },
                    React.createElement("span", null,
                        MAX_NOTES_VALUE - ((description === null || description === void 0 ? void 0 : description.length) || 0),
                        " characters remaining")))),
        React.createElement("div", { "data-slot": "dialog-footer-content" },
            React.createElement("div", { className: "neon-2_8_0-button-layout" },
                React.createElement(NeonButton, { id: 'mfe-beh-smart-groups-modal-dialog-cancel', dataText: 'Cancel', dataType: 'Default', onClick: addNotesModalCloseHandler }),
                React.createElement(NeonButton, __assign({ id: 'mfe-beh-smart-groups-modal-dialog-confirm', dataText: "Save", dataType: 'primary', onClick: function (event) {
                        event.preventDefault();
                        smartGroupAddNotesOnSave(authToken, smartGroupId, selectedStudents, description || '', addStudentNotesConfirmResponseHandler);
                    } }, buttonProps, { dataTooltipPlacement: 'top-end' }))))));
};
export default SmartGroupAddNotes;
